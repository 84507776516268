<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <div class="m-1">
                <b-row cols="12" class=" px-2">
                    <b-col cols="12">
                        <!-- Search -->
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <b-icon-search />
                            </b-input-group-prepend>
                            <b-form-input type="text" autofocus v-model="filters.search" placeholder="Search"
                                @input="onSearch" />
                        </b-input-group>
                    </b-col>

                </b-row>
            </div>
        </b-card>

        <!-- Universirty List -->
        <b-card no-body>
            <b-table responsive show-empty class="position-relative" empty-text="No matching records found"
                :per-page="pagination.perPage" :current-page="pagination.currentPage" :items="loanBanks"
                :fields="tableFields">

                <!-- Cell for 'el_name' -->
                <template #cell(el_id)="row">
                    {{ row.item.el_id || '-' }}
                </template>

                <template #cell(el_name)="row">
                    {{ row.item.el_name || '-' }}
                </template>

                <!-- Cell for 'el_logo' -->
                <template #cell(el_logo)="row">
                    <img :src="FILESURL + row.item.el_logo" alt="Logo" class="img-fluid"
                        style="max-width: 100px; max-height: 50px;" />
                </template>

                <template #cell(interested)="row">
                    <b-button variant="primary" v-if="row.item.enquiry_exists" disabled="true">Enquired</b-button>
                    <b-button variant="primary" v-else @click="markAsInterested(row.item.el_id)">Enquire Now</b-button>
                </template>
            </b-table>
            <div class="mx-2" v-if="totalData > 0">
                <b-pagination v-model="pagination.currentPage" :total-rows="totalData" base-url="#" align="right" />
            </div>
        </b-card>

    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BModal,
    BTooltip,
    BPagination,
    BFormRadio,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BIconSearch,
} from "bootstrap-vue";
import vSelect from "vue-select";
import StudentServices from "@/apiServices/StudentServices";
import MultiStateSwitch from "@/views/components/MultiStateSwitch.vue";
import { FILESURL } from "@/config";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        vSelect,
        BCard,
        BModal,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        BFormGroup,
        BFormRadio,
        BFormCheckbox,
        BInputGroup,
        BInputGroupPrepend,
        BIconSearch,
        MultiStateSwitch,
    },
    data() {
        return {
            filters: {
                search: ''
            },
            loanBanks: [],
            totalData: 0,
            pagination: {
                currentPage: 1,
                perPage: 20,
                perPageOptions: [5, 10, 20, 30],
            },
            debounce: null,
            FILESURL,
            tableFields: [
                { key: 'el_id', label: 'ID' },
                { key: 'el_name', label: 'Name' },
                { key: 'el_logo', label: 'Logo' },
                { key: 'interested', label: 'Actions' },
            ]
        };
    },
    methods: {
        async getAllLoanBanks() {
            try {
                const res = await StudentServices.getAllLoanBanks({
                    ...this.filters,
                });
                this.loanBanks = res.data.data.data;
                this.totalData = res.data.data.total;
            } catch (error) {
                console.error(`Error in getStatusWiseStudents `, error);
            }
        },
        async markAsInterested(el_id) {
            try {
                const payload = { el_id }
                const res = await StudentServices.studentInterestedForLoan(payload)
                if (res.data.status) {
                    this.$toast({
                        component: ToastificationContentVue,
                        props: {
                            title: `Inquiry Sent`,
                            icon: "Check",
                            variant: "success",
                        },
                    });
                    this.getAllLoanBanks()
                }
            } catch (error) {
                console.error(`Error in markAsInterested `, error);x
            }
        },
        onChange() {
            this.$nextTick(() => {
                this.getAllLoanBanks();
            });
        },
        onSearch() {
            if (this.debounce) {
                this.debounce = null;
            }
            this.debounce = setTimeout(() => {
                this.onChange();
            }, 1000);
        },

        async resetFilters() {
            this.filters = {
                search: "",
            };
            this.getAllLoanBanks();
        },
    },
    beforeMount() {
        this.getAllLoanBanks();
    },
};
</script>